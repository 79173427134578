<template>
  <div>
    <v-card outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold title">Reports</span>
      </v-card-title>
      <v-card-text v-if="status.getting">
        <preloader text="Please wait..." />
      </v-card-text>
      <v-card-text class="pt-4" v-else-if="!status.getting && !reports.length">
        <v-alert type="info" border="left" class="mb-0" dense text>
          No reports found.
        </v-alert>
      </v-card-text>
      <v-simple-table v-else-if="!status.getting && reports.length">
        <template #default>
          <thead>
            <tr>
              <th>Issue</th>
              <th>Reported By</th>
              <th>Created At</th>
              <th>Recipe Details</th>
              <th>Action</th>
            </tr>
          </thead>
          <v-fade-transition tag="tbody" group>
            <tr v-for="report in reports" :key="report.id">
              <td width="30%">
                <p class="mt-4">{{ report.issue }}</p>
              </td>
              <td>
                {{ $store.getters['members/userData'](report.user).firstName }} {{ $store.getters['members/userData'](report.user).lastName }}
                <span>(<a :href="`mailto:${$store.getters['members/userData'](report.user).email}`">{{ $store.getters['members/userData'](report.user).email }}</a>)</span>
              </td>
              <td class="caption">{{ formatTime(report.created) }}</td>
              <td>
                <v-btn @click="showCardDialog(report.recipe)" color="primary" x-small outlined>View Recipe Card</v-btn>
              </td>
              <td>
                <v-btn 
                  @click="closeReport(report)" 
                  :loading="status.closing.includes(report.id)"
                  class="text-none ls-0" 
                  small text
                >Mark as resolved</v-btn>
              </td>
            </tr>
          </v-fade-transition>
        </template>
      </v-simple-table>        
    </v-card>

    <recipe-card
      v-if="recipe"
      :recipe="recipe"
      :show="showCard"
      @cancel="closeRecipeCard()"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

import RecipeCard from '../recipe/components/RecipeCard'

export default {
  metaInfo: {
    title: 'Reports'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      recipe: null,
      showCard: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeCard
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      reports: state => state.report.reports,
      status: state => state.report.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('report', [
      'getReports',
      'closeReport'
    ]),

    formatTime(time) {
      return moment(time.toDate()).fromNow()
    },

    closeRecipeCard() {
      this.showCard = false
      this.recipe = null
    },

    showCardDialog(recipe) {
      this.recipe = this.$store.getters['recipes/recipe'](recipe)
      if (Object.keys(this.recipe).length) this.showCard = true
      else this.$store.dispatch('showError', 'Recipe not found')
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getReports()
  }
}
</script>